<template>
    <header class="header">
        <div class="header-inner"> 
            <div class="logo">
                <a @click="goToHome()">
                    <img src="../assets/images/logo.png" alt="">
                </a>
            </div>
            <div class="navigation" :class="{'toggle' : showTopMenu}">
                    <a class="toggle-button" @click="showTopMenu = !showTopMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                <ul>
                    <li><a @click="goToAbout()" :class="{'active' : $route.name.includes('About')}">About Us</a></li>
                    <li><a @click="goToService()" :class="{'active' : $route.name.includes('Service')}">Services</a></li>
                    <li><a @click="goToContact()" :class="{'active' : $route.name.includes('Contact')}">Contact Us</a></li>
                    <!-- <li class="only-mobile"><a @click="goToBecomeDriver()" :class="{'active' : $route.name.includes('BecomeDriver')}">Become a Driver</a></li> -->
                </ul>
            </div>
            <div class="navigation-button">
                <!-- <a @click="goToBecomeDriver()" :class="{'active' : $route.name.includes('BecomeDriver')}">Become a Driver</a> -->
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "Header",
    data() {
        return{
            showTopMenu: false,
        }
    },
    methods: {
        goToHome(){
            this.showTopMenu = false;
            this.$router.push({ path: "/" });
        },
        goToAbout(){
            this.showTopMenu = false;
            console.log(this.showTopMenu);
            this.$router.push({ path: "/about" });
        },
        goToService(){
            this.showTopMenu = false;
            this.$router.push({ path: "/services" });
        },
        goToBecomeDriver(){
            this.showTopMenu = false;
            this.$router.push({ path: "/become-driver" });
        },
        goToContact(){
            this.showTopMenu = false;
            this.$router.push({ path: "/contact" });
        },
    }
}
</script>
